@use "abstract/variables";
@use "layout/content";

//common styles

body {
  font-family: variables.$font-family;
  font-size: variables.$font-family;
  color: variables.$font-color;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.transparent-background {
  background-color:rgba(0, 0, 0, 0);
}

//angular material overwrites

.mat-form-field-label{
  color: #878787 !important;
}

.mat-form-field-ripple {
  background: variables.$color-primary !important;
}

.mat-form-field-appearance-fill .mat-form-field-underline:before {
  background-color: variables.$color-primary !important;
}

.mat-progress-spinner circle, .mat-spinner circle {
  stroke: variables.$color-primary;
}

.mat-step-text-label {
  @include content.regularStepperText();
  color: variables.$custom-white;
}

.mat-stepper-horizontal-line {
  border-top-color: variables.$custom-white;
}

.mat-step-icon.mat-step-icon-state-number.mat-step-icon-selected {
  @include content.regularStepperText();
  background-color: variables.$color-primary;
  color: variables.$custom-grey;
}

.mat-step-icon.mat-step-icon-state-number {
  @include content.regularStepperText();
  background-color: variables.$custom-white;
  color: variables.$custom-grey;
}

.mat-step-icon.mat-step-icon-state-done, .mat-step-icon.mat-step-icon-state-edit {
  @include content.regularStepperText();
  background-color: variables.$color-primary;
  color: variables.$custom-grey;
}

.mat-vertical-content {
  padding: 0 8px 8px 8px !important;
}

.mat-stepper-vertical-line {
  border-top-color: variables.$custom-white !important;
}
