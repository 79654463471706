$color-primary: #FFD900;

$custom-black: #0A0A0A;
$custom-white: #FFFFFF;
$custom-grey: #424242;

$font-family: Roboto, sans-serif;
$font-size: 14px;
$font-color: #333;

$navbar-height: 60px;
$navbar-border: 3px;
$max-width: 1080px;
$content-height: calc(100vh - #{$navbar-height});

$padding-phone: 16px;
$padding-tablet: 24px;
$padding-desktop: 32px;

