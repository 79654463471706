@use "abstract/variables";

@mixin regularContent() {
  display: flex;
  flex-direction: column;
  height: calc(100vh - #{variables.$navbar-height});
  margin: auto;
  max-width: variables.$max-width;
  align-items: center;
  box-sizing: border-box;
}

@mixin regularHeader() {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 38px;
  line-height: 45px;
}

@mixin regularText() {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
}

@mixin regularStepperText() {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
}

@mixin regularBackground() {
  background-image: url("/assets/motor-768750_1920.jpg");
  background-size: cover;
  width: 100vw;
  z-index: -2;
  position: absolute;
}

@mixin regularFade() {
  position: absolute;
  background: variables.$custom-black;
  opacity: 0.6;
  width: 100vw;
  z-index: -1;
}

// image preview
.ngxImageZoomFullContainer.ngxImageZoomLensEnabled {
  border: 2px solid grey !important;
}

.ngxImageZoomThumbnail {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.ngxImageZoomContainer {
  overflow: auto;
  //https://github.com/wittlock/ngx-image-zoom/issues/6
  width: 100% !important;
  height: 100% !important;
}
