/* http://meyerweb.com/eric/tools/css/reset/
   v5.0.1 | 20191019
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, menu, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
main, menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, main, menu, nav, section {
  display: block;
}

/* HTML5 hidden-attribute fix for newer browsers */
*[hidden] {
  display: none;
}

body {
  line-height: 1;
}

menu, ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

.ngxImageZoomFullContainer.ngxImageZoomLensEnabled {
  border: 2px solid grey !important;
}

.ngxImageZoomThumbnail {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.ngxImageZoomContainer {
  overflow: auto;
  width: 100% !important;
  height: 100% !important;
}

body {
  font-family: Roboto, sans-serif;
  font-size: Roboto, sans-serif;
  color: #333;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.transparent-background {
  background-color: rgba(0, 0, 0, 0);
}

.mat-form-field-label {
  color: #878787 !important;
}

.mat-form-field-ripple {
  background: #FFD900 !important;
}

.mat-form-field-appearance-fill .mat-form-field-underline:before {
  background-color: #FFD900 !important;
}

.mat-progress-spinner circle, .mat-spinner circle {
  stroke: #FFD900;
}

.mat-step-text-label {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #FFFFFF;
}

.mat-stepper-horizontal-line {
  border-top-color: #FFFFFF;
}

.mat-step-icon.mat-step-icon-state-number.mat-step-icon-selected {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  background-color: #FFD900;
  color: #424242;
}

.mat-step-icon.mat-step-icon-state-number {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  background-color: #FFFFFF;
  color: #424242;
}

.mat-step-icon.mat-step-icon-state-done, .mat-step-icon.mat-step-icon-state-edit {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  background-color: #FFD900;
  color: #424242;
}

.mat-vertical-content {
  padding: 0 8px 8px 8px !important;
}

.mat-stepper-vertical-line {
  border-top-color: #FFFFFF !important;
}

@media print {
  app-dashboard {
    display: none;
  }

  app-print-layout {
    display: block;
  }
}